import React from 'react'
import {
  Datagrid, TextField, EditButton, Edit,
  TextInput, ReferenceManyField, LongTextInput, ReferenceField,
  TabbedForm, FormTab, FormDataConsumer, Button,
  required, minLength, DateField, ShowButton, Pagination,

} from 'react-admin'
import CreateIcon from '@material-ui/icons/Create'

import AccountEditTitle from './AccountEditTitle'
import AccountEditToolbar from './AccountEditToolbar'
import MapView from '../../components/MapView'

import CustomActions from './CustomActions'

const validateName = [required()]
const validateDescription = [required(), minLength(20)]
const validateCtaText = [required(), minLength(3)]
const validateCtaUrl = [required()]
const validateCtaImage = [required()]

const AccountEdit = ({ history, ...rest }) => (
  <>
    <Edit title={<AccountEditTitle />} actions={<CustomActions />} history={history} {...rest}>
      <TabbedForm toolbar={<AccountEditToolbar />}>
        <FormTab label="General">
          <TextField source="id" />
          <TextInput source="name" validate={validateName} />
          <LongTextInput source="description" validate={validateDescription} />
          <TextField source="type" />
        </FormTab>
        <FormTab label="Call To Action">
          <TextInput source="cta_text" validate={validateCtaText} />
          <TextInput source="cta_url" validate={validateCtaUrl} />
          <TextInput source="cta_image" validate={validateCtaImage} />
        </FormTab>
        <FormTab label="Members">
          <ReferenceManyField
            label="Users that have access to this account"
            sort={{ field: 'user_id', order: 'ASC' }}
            reference="user_account"
            target="account_id"
          >
            <Datagrid>
              <ReferenceField source="user_id" reference="user" label="Email" linkType={false}>
                <TextField source="email" />
              </ReferenceField>
              <ReferenceField source="user_id" reference="user" label="Name" linkType={false}>
                <TextField source="name" />
              </ReferenceField>
              <FormDataConsumer>
                {({ record }) => (
                  <Button label="Edit" onClick={() => history.push(`/user/${record.user_id}`)}>
                    <CreateIcon />
                  </Button>
                )}
              </FormDataConsumer>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab
          label="Geofences"
        >
          <ReferenceManyField
            sort={{ field: 'name', order: 'ASC' }}
            label="Geofences owned by this account"
            reference="geofence"
            target="account_id"
          >
            <Datagrid>
              <TextField source="name" />
              <TextField source="points" />
              <TextField source="status" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceManyField
            sort={{ field: 'name', order: 'ASC' }}
            label=""
            reference="geofence"
            target="account_id"
          >
            <MapView />
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Rewards">
          <ReferenceManyField
            label="Rewards owned by this account"
            reference="reward"
            target="account_id"
            sort={{ field: 'name', order: 'ASC' }}
          >
            <Datagrid>
              <TextField source="name" />
              <TextField source="points" />
              <TextField source="status" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Events">
          <ReferenceManyField
            sort={{ field: 'timestamp', order: 'DESC' }}
            filter={{ type: 'geofence' }}
            label="Events triggered by this account's geofences"
            reference="event"
            target="account_id"
            pagination={<Pagination />}
            perPage={10}
          >
            <Datagrid>
              <TextField source="status" />
              <TextField source="geofence_action" label="Action" />
              <TextField source="points" />
              <DateField source="timestamp" showTime label="Created at" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceManyField
            sort={{ field: 'timestamp', order: 'DESC' }}
            filter={{ type: 'geofence' }}
            label=""
            reference="event"
            target="account_id"
          >
            <MapView />
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Funds">
          <ReferenceManyField
            sort={{ field: 'points', order: 'DESC' }}
            label="Available funds per user"
            reference="fund"
            target="account_id"
            pagination={<Pagination />}
            perPage={10}
          >
            <Datagrid>
              <ReferenceField source="user_id" reference="user" label="User" linkType>
                <TextField source="email" />
              </ReferenceField>
              <TextField source="points" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Redemptions">
          <ReferenceManyField
            sort={{ field: 'timestamp', order: 'DESC' }}
            label="Redemptions made by this user"
            reference="redemption"
            target="account_id"
            pagination={<Pagination />}
            perPage={10}
          >
            <Datagrid>
              <ReferenceField source="user_id" reference="user" label="User" linkType>
                <TextField source="email" />
              </ReferenceField>
              <ReferenceField source="reward_id" reference="reward" label="Reward" linkType>
                <TextField source="name" />
              </ReferenceField>
              <DateField source="timestamp" showTime label="Created at" />
              <TextField source="points" />
              <TextField source="status" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  </>
)

export default AccountEdit
