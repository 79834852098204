import React from 'react'
import { Filter, SelectInput } from 'react-admin'

const UserListFilter = props => (
  <Filter {...props}>
    <SelectInput
      label="Role"
      source="role"
      allowEmpty={false}
      choices={[
        { id: 'ADMIN', name: 'ADMIN' },
        { id: 'CLIENT', name: 'CLIENT' },
        { id: 'MOBILE', name: 'MOBILE' },
      ]}
    />
  </Filter>
)

export default UserListFilter
