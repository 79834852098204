import React from 'react'
import {
  List, Datagrid, TextField, EditButton,
  ReferenceField,
} from 'react-admin'

import GeofenceListFilter from './GeofenceListFilter'

const GeofenceList = props => (
  <List
    {...props}
    sort={{ field: 'account.name', order: 'ASC' }}
    bulkActions={false}
    filters={<GeofenceListFilter />}
  >
    <Datagrid>
      <ReferenceField source="account_id" reference="account" label="Owner" sortBy="account.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="points" />
      <TextField source="refresh_rate" />
      <TextField source="radius" />
      <TextField source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

export default GeofenceList
