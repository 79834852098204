import React from 'react'
import {
  List, Datagrid, TextField, EditButton,
} from 'react-admin'

import UserListFilter from './UserListFilter'

const UserList = props => (
  <List
    {...props}
    sort={{ field: 'email', order: 'ASC' }}
    bulkActions={false}
    filters={<UserListFilter />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="role" />
      <EditButton />
    </Datagrid>
  </List>
)

export default UserList
