import React from 'react'
import {
  SimpleForm, Create, ReferenceInput, SelectInput,
  required,
} from 'react-admin'

const validateUser = [required()]
const validateAccount = [required()]

const UserAccountCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source="account_id"
        reference="account"
        validate={validateAccount}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="user_id"
        reference="user"
        validate={validateUser}
        sort={{ field: 'email', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="email" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default UserAccountCreate
