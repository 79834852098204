import React from 'react'
import {
  NumberInput, ReferenceInput, SelectInput, Filter,
} from 'react-admin'

const FundListFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="User"
      source="user_id"
      reference="user"
      allowEmpty={false}
      sort={{ field: 'email', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput
      label="Owner"
      source="account_id"
      reference="account"
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="points" step={1} />
  </Filter>
)

export default FundListFilter
