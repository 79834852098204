import React from 'react'
import { css } from 'styled-components/macro'

const colors = {
  warning: '#EFC210',
  error: '#BB1C1C',
}

const EnvBanner = () => {
  let system
  let color

  switch (process.env.REACT_APP_PROJECT_ID) {
    case 'pbeeapp-dev':
      system = 'development'
      color = colors.error
      break
    case 'pbeeapp-staging':
      system = 'staging'
      color = colors.warning
      break
    default:
      return null
  }

  return (
    <div
      css={css`
        color: white;
        background-color: ${color};
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: .85rem;
      `}
    >
      {`Warning! This is a ${system} environment`}
    </div>
  )
}

export default EnvBanner
