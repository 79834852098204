import React from 'react'
import {
  List, Datagrid, TextField, EditButton,
  ReferenceField, DateField,
} from 'react-admin'

import RedemptionListFilter from './RedemptionListFilter'
import RedemptionListActions from './RedemptionListActions'

const RedemptionList = props => (
  <List
    {...props}
    sort={{ field: 'timestamp', order: 'DESC' }}
    bulkActions={false}
    filters={<RedemptionListFilter />}
    actions={<RedemptionListActions />}
  >
    <Datagrid>
      <ReferenceField source="user_id" reference="user" label="User" sortBy="user.email">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="account_id" reference="account" label="Owner" sortBy="account.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="reward_id" reference="reward" sortBy="reward.name">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="timestamp" showTime label="Started at" />
      <TextField source="status" />
      <TextField source="reward_type" />
      <EditButton />
    </Datagrid>
  </List>
)

export default RedemptionList
