import React from 'react'
import {
  SimpleForm, SelectInput, Create, ReferenceInput,
  required,
} from 'react-admin'

const validateUserEmail = [required()]
const validateReward = [required()]

const RedemptionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source="user_id"
        reference="user"
        label="User Email"
        validate={validateUserEmail}
        sort={{ field: 'email', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput
        source="reward_id"
        reference="reward"
        validate={validateReward}
        sort={{ field: 'points', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default RedemptionCreate
