import React, { useEffect, useState } from 'react'
import { Admin, Resource } from 'react-admin'
import { useAuthState } from 'react-firebase-hooks/auth'
import UserIcon from '@material-ui/icons/PersonOutline'
import GeofenceIcon from '@material-ui/icons/PlaceOutlined'
import RewardIcon from '@material-ui/icons/CardGiftcard'
import EventIcon from '@material-ui/icons/Event'
import AccountIcon from '@material-ui/icons/WorkOutline'
import FundIcon from '@material-ui/icons/LocalAtm'
import RedemptionIcon from '@material-ui/icons/LocalActivityOutlined'
import UserAccountIcon from '@material-ui/icons/PeopleOutline'
import AdjustmentIcon from '@material-ui/icons/AdjustOutlined'
import get from 'lodash.get'
import { useLocalStorage } from '@rehooks/local-storage'

import { UserList, UserEdit, UserCreate } from '../resources/user'
import { EventList, EventShow } from '../resources/event'
import { GeofenceList, GeofenceEdit, GeofenceCreate } from '../resources/geofence'
import { RewardList, RewardEdit, RewardCreate } from '../resources/reward'
import { RedemptionList, RedemptionEdit, RedemptionCreate } from '../resources/redemption'
import { FundList, FundShow } from '../resources/fund'
import { AccountList, AccountEdit, AccountCreate } from '../resources/account'
import { UserAccountList, UserAccountCreate } from '../resources/userAccount'
import { AdjustmentList, AdjustmentCreate, AdjustmentShow } from '../resources/adjustment'
import LoginPage from './LoginPage'
import Layout from './Layout'

import firebase, { init } from '../utils/firebase'
import authProvider from '../utils/authProvider'
import dataProvider from '../utils/dataProvider'
import { endpoint, headers, config } from '../utils/setup'
import theme from '../utils/theme'

init()

const App = () => {
  const [admin, setAdmin] = useState()
  const [darkMode] = useLocalStorage('pbee-admin:darkMode')
  const [user, loading] = useAuthState(firebase.auth())

  useEffect(() => {
    const getClaims = async () => {
      const result = user ? await firebase.auth().currentUser.getIdTokenResult() : null
      setAdmin(get(result, 'claims.admin', false))
    }

    if (user && !loading) getClaims()
  }, [user, loading])

  if (loading) return <div>Loading...</div>

  if (user && admin === false) {
    const logout = () => firebase.auth().signOut()

    return (
      <div>
        You are not an admin. Visit the
        {' '}
        <a href="https://portal.pointbee.app">Pointbee Portal</a>
        {' '}
        to manage your account or please
        {' '}
        <a href={document.location.href} onClick={logout}>logout</a>
        .
      </div>
    )
  }

  return (
    <Admin
      loginPage={LoginPage}
      dataProvider={dataProvider(endpoint, headers, config)}
      authProvider={authProvider}
      theme={theme(JSON.parse(darkMode))}
      title="Pointbee"
      appLayout={Layout}
    >
      <Resource name="account" list={AccountList} edit={AccountEdit} create={AccountCreate} icon={AccountIcon} />
      <Resource name="user" list={UserList} edit={UserEdit} create={UserCreate} icon={UserIcon} />
      <Resource name="user_account" options={{ label: 'Members' }} list={UserAccountList} create={UserAccountCreate} icon={UserAccountIcon} />
      <Resource name="geofence" list={GeofenceList} edit={GeofenceEdit} create={GeofenceCreate} icon={GeofenceIcon} />
      <Resource name="reward" list={RewardList} edit={RewardEdit} create={RewardCreate} icon={RewardIcon} />
      <Resource name="event" list={EventList} show={EventShow} icon={EventIcon} />
      <Resource name="fund" list={FundList} show={FundShow} icon={FundIcon} />
      <Resource name="redemption" list={RedemptionList} edit={RedemptionEdit} create={RedemptionCreate} icon={RedemptionIcon} />
      <Resource name="adjustment" list={AdjustmentList} show={AdjustmentShow} create={AdjustmentCreate} icon={AdjustmentIcon} />
    </Admin>
  )
}

export default App
