import React from 'react'
import {
  TextField, Show, SimpleShowLayout,
  ReferenceField, DateField,
} from 'react-admin'

import CustomActions from './CustomActions'

const AdjustmentShow = props => (
  <Show actions={<CustomActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="user_id" reference="user">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="account_id" reference="account">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="points" />
      <TextField source="status" />
      <TextField source="comment" component="pre" />
      <DateField source="created_at" showTime label="Created at" />
      <DateField source="updated_at" showTime label="Updated at" />
    </SimpleShowLayout>
  </Show>
)

export default AdjustmentShow
