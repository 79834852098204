import React from 'react'
import { SelectInput, Filter } from 'react-admin'

const AccountListFilter = props => (
  <Filter {...props}>
    <SelectInput
      label="Type"
      source="type"
      allowEmpty={false}
      choices={[
        { id: 'BUSINESS', name: 'BUSINESS' },
        { id: 'PERSONAL', name: 'PERSONAL' },
      ]}
    />
  </Filter>
)

export default AccountListFilter
