import React from 'react'
import {
  TextField, ReferenceField, TabbedShowLayout, DateField,
  Tab, Show, FormDataConsumer,
} from 'react-admin'

import Map from '../../components/Map'

const EventShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="General">
        <TextField source="id" />
        <ReferenceField source="user_id" reference="user">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField source="account_id" reference="account">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="geofence_id" reference="geofence">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="geofence_action" label="Action" />
        <TextField source="status" />
        <TextField source="type" />
        <TextField source="points" />
        <DateField source="timestamp" showTime label="Device timestamp" />
      </Tab>
      <Tab label="Location">
        <TextField source="location.coordinates[1]" label="Latitude" />
        <TextField source="location.coordinates[0]" label="Longitude" />
        <TextField source="altitude" />
        <TextField source="altitude_accuracy" />
        <FormDataConsumer>
          {({ formData }) => (
            <Map
              markers={[{
                key: formData.id,
                lat: formData.location.coordinates[1],
                lng: formData.location.coordinates[0],
              }]}
              center={{
                lat: formData.location.coordinates[1],
                lng: formData.location.coordinates[0],
              }}
              defaultZoom={15}
            />
          )}
        </FormDataConsumer>
      </Tab>
      <Tab label="Technical">
        <TextField source="uuid" label="ID from device" />
        <TextField source="activity_type" />
        <TextField source="activity_confidence" />
        <TextField source="speed" />
        <TextField source="heading" />
        <TextField source="accuracy" />
        <TextField source="odometer" />
      </Tab>
      <Tab label="Device">
        <TextField source="battery_level" />
        <TextField source="battery_is_charging" />
        <TextField source="device_model" />
        <TextField source="device_platform" />
        <TextField source="device_manufacturer" />
        <TextField source="app_version" />
        <TextField source="app_build" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default EventShow
