import React from 'react'
import get from 'lodash.get'

import Map from './Map'

const MapView = ({ ids = [], data = {}, ...rest }) => {
  const markers = []

  ids.forEach((id) => {
    const lat = get(data[id], 'location.coordinates[1]')
    const lng = get(data[id], 'location.coordinates[0]')
    const { radius } = data[id]

    if (lat && lng) {
      markers.push({
        key: id,
        lat,
        lng,
        radius,
      })
    }
  })

  if (ids.length === 0) return null

  return (
    <Map
      markers={markers}
      boundToMarkers
      {...rest}
    />
  )
}

export default MapView
