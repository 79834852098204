import React from 'react'
import { CardActions, CreateButton, ExportButton } from 'react-admin'
import MuiButton from '@material-ui/core/Button'
import InfoIcon from '@material-ui/icons/InfoOutlined'

const onClick = () => window.open('https://docs.google.com/document/d/1t_edULLaECbpLxOabdUWefksTUdcArl-znNYh8mX-vQ')

const RedemptionListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {bulkActions && React.cloneElement(bulkActions, {
      basePath,
      filterValues,
      resource,
      selectedIds,
      onUnselectItems,
    })}
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <MuiButton
      style={{
        fontSize: 13,
        color: 'white',
        marginBottom: 20,
      }}
      onClick={onClick}
    >
      <InfoIcon />
      &nbsp;&nbsp;Instructions
    </MuiButton>
  </CardActions>
)

export default RedemptionListActions
