import React from 'react'
import {
  Datagrid, TextField, Edit, TextInput,
  ReferenceField, NumberInput, TabbedForm, FormTab,
  SelectInput, required, minValue, FormDataConsumer,
  DateField, Pagination, ReferenceManyField, ShowButton,
  maxValue,
} from 'react-admin'

import GeofenceEditTitle from './GeofenceEditTitle'
import CustomActions from './CustomActions'
import CustomToolbar from './CustomToolbar'
import MapView from '../../components/MapView'
import Map from '../../components/Map'

const validateName = [required()]
const validateRefreshRate = [required(), minValue(30), maxValue(43200, 'Must be 43,200 (30 days) or less')]
const validatePoints = [required(), minValue(5), maxValue(15)]
const validateRadius = [required(), minValue(50), maxValue(500)]
const validateStatus = [required()]
const validateLocation = [required()]

const GeofenceEdit = props => (
  <Edit title={<GeofenceEditTitle />} actions={<CustomActions />} {...props}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="General">
        <TextField source="id" />
        <ReferenceField source="account_id" reference="account" label="Owner">
          <TextField source="name" />
        </ReferenceField>
        <TextInput source="name" validate={validateName} />
        <NumberInput source="refresh_rate" validate={validateRefreshRate} label="Refresh rate (milliseconds)" />
        <NumberInput source="points" validate={validatePoints} />
        <NumberInput source="radius" validate={validateRadius} label="Radius (meters)" />
        <TextField source="type" />
        <SelectInput
          validate={validateStatus}
          source="status"
          choices={[
            { id: 'ACTIVE', name: 'ACTIVE' },
            { id: 'INACTIVE', name: 'INACTIVE' },
          ]}
        />
      </FormTab>
      <FormTab label="Location">
        <NumberInput source="location.coordinates[1]" label="Latitude" validate={validateLocation} />
        <NumberInput source="location.coordinates[0]" label="Longitude" validate={validateLocation} />
        <FormDataConsumer>
          {({ formData }) => (
            <Map
              markers={[{
                key: formData.id,
                lat: formData.location.coordinates[1],
                lng: formData.location.coordinates[0],
                radius: formData.radius,
              }]}
              center={{
                lat: formData.location.coordinates[1],
                lng: formData.location.coordinates[0],
              }}
              defaultZoom={15}
            />
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Events">
        <ReferenceManyField
          sort={{ field: 'timestamp', order: 'DESC' }}
          filter={{ type: 'geofence' }}
          label="Events triggered by this geofence"
          reference="event"
          target="geofence_id"
          pagination={<Pagination />}
          perPage={10}
        >
          <Datagrid>
            <TextField source="status" />
            <TextField source="geofence_action" label="Action" />
            <TextField source="points" />
            <DateField source="timestamp" showTime label="Created at" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          sort={{ field: 'timestamp', order: 'DESC' }}
          filter={{ type: 'geofence' }}
          label=""
          reference="event"
          target="geofence_id"
        >
          <MapView />
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default GeofenceEdit
