import React from 'react'
import { CardActions, CloneButton } from 'react-admin'

const CustomActions = ({ basePath, data }) => (
  <CardActions>
    <CloneButton basePath={basePath} record={data} />
  </CardActions>
)

export default CustomActions
