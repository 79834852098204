import React from 'react'
import {
  ReferenceInput, SelectInput, Filter,
} from 'react-admin'

const UserAccountListFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="User"
      source="user_id"
      reference="user"
      allowEmpty={false}
      sort={{ field: 'email', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput
      label="Owner"
      source="account_id"
      reference="account"
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

export default UserAccountListFilter
