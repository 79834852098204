import React from 'react'
import {
  AppBar as RaAppBar,
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { useLocalStorage } from '@rehooks/local-storage'
import { css } from 'styled-components/macro'

const AppBar = (props) => {
  const [darkMode, setDarkMode] = useLocalStorage('pbee-admin:darkMode')

  return (
    <RaAppBar
      {...props}
    >
      <Typography
        variant="title"
        color="inherit"
        id="react-admin-title"
      />
      <span
        css={css`flex: 1;`}
      />
      <FormControlLabel
        control={(
          <Switch
            checked={JSON.parse(darkMode)}
            onChange={() => setDarkMode(!JSON.parse(darkMode))}
          />
        )}
        label="Dark Mode"
      />
    </RaAppBar>
  )
}

export default AppBar
