/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import firebase from '@firebase/app'
import get from 'lodash.get'
import orderBy from 'lodash.orderby'

/* eslint-disable no-case-declarations */
const bulkQuery = {
  type: 'bulk',
  args: [],
}

const selectQuery = {
  type: 'select',
  args: {
    table: { schema: '', name: '' },
    columns: ['*'],
  },
}

const countQuery = {
  type: 'count',
  args: {
    table: { schema: '', name: '' },
    where: {},
  },
}

const insertQuery = {
  type: 'insert',
  args: {
    table: { schema: '', name: '' },
    objects: [],
    returning: [],
  },
}

const updateQuery = {
  type: 'update',
  args: {
    table: { schema: '', name: '' },
    $set: {},
    where: {},
    returning: [],
  },
}

const deleteQuery = {
  type: 'delete',
  args: {
    table: { schema: '', name: '' },
    $set: {},
    where: {},
    returning: [],
  },
}

const DEFAULT_PRIMARY_KEY = 'id'

const cloneQuery = (query) => JSON.parse(JSON.stringify(query))

export default (serverEndpoint, headers, config) => {
  const getTableSchema = (resource) => {
    let tableName
    let schema

    // parse schema in resource
    if (resource && resource.split('.').length === 1) {
      schema = 'public'
      tableName = resource
    } else if (resource && resource.split('.').length === 2) {
      const resourceSplit = resource.split('.')

      schema = resourceSplit[0]
      tableName = resourceSplit[1]
    } else {
      throw new Error(JSON.stringify({ error: 'Invalid table/schema resource' }))
    }
    return { schema, tableName }
  }

  const getPrimaryKey = (resource) => {
    let primaryKey = DEFAULT_PRIMARY_KEY

    if (config && config.primaryKey[resource]) {
      primaryKey = config.primaryKey[resource]
    }
    return primaryKey
  }

  const addFilters = (where, filter) => {
    if (!filter) return where

    const filterKeys = Object.keys(filter)
    if (filterKeys.length === 0) return where

    const whereCopy = Object.assign(where)
    filterKeys.forEach((key) => {
      whereCopy[key] = filter[key]
    })
    return whereCopy
  }

  const convertDataRequestToHTTP = (type, resource, params) => {
    const options = {}
    let finalQuery = {}

    const tableSchema = getTableSchema(resource)

    const { schema, tableName } = tableSchema

    const pkValue = getPrimaryKey(resource)
    const primaryKey = Array.isArray(pkValue) ? pkValue[0] : pkValue

    switch (type) {
      case 'GET_LIST':
        // select multiple
        const finalSelectQuery = cloneQuery(selectQuery)
        const finalCountQuery = cloneQuery(countQuery)

        finalSelectQuery.args.table = { name: tableName, schema }
        finalSelectQuery.args.limit = params.pagination.perPage
        finalSelectQuery.args.offset = (params.pagination.page * params.pagination.perPage) - params.pagination.perPage
        finalSelectQuery.args.where = params.filter
        finalSelectQuery.args.order_by = { column: params.sort.field || primaryKey, type: params.sort.order.toLowerCase() }
        finalCountQuery.args.table = { name: tableName, schema }
        finalCountQuery.args.where = {}
        finalCountQuery.args.where[primaryKey] = { $ne: null }
        finalCountQuery.args.where = addFilters(finalCountQuery.args.where, params.filter)
        finalQuery = cloneQuery(bulkQuery)
        finalQuery.args.push(finalSelectQuery)
        finalQuery.args.push(finalCountQuery)
        break
      case 'GET_ONE':
        // select one
        finalQuery = cloneQuery(selectQuery)
        finalQuery.args.table = { name: tableName, schema }
        finalQuery.args.where = {}
        if (Array.isArray(pkValue)) {
          pkValue.forEach((p, i) => {
            finalQuery.args.where[p] = { $eq: params.id.split('_')[i] }
          })
        } else {
          finalQuery.args.where[primaryKey] = { $eq: params.id }
        }
        break
      case 'CREATE':
        // create one
        const createFields = Object.keys(params.data)

        // remove fields that we never want to update
        if (resource === 'adjustment') delete params.data.status

        finalQuery = cloneQuery(insertQuery)
        finalQuery.args.table = { name: tableName, schema }
        finalQuery.args.objects.push(params.data)
        createFields.push(primaryKey)
        finalQuery.args.returning = createFields
        break
      case 'UPDATE':
        // update one

        // remove fields that do not exist in database
        const updateFields = Object.keys(params.data).filter((k) => k !== 'emailVerified' && k !== 'dbEmail')

        // remove fields that we never want to update
        delete params.data.id
        delete params.data.updated_at
        delete params.data.created_at

        // remove user fields separately in case they have the same name as a different resource
        if (resource === 'user') {
          delete params.data.name
          delete params.data.email
          delete params.data.emailVerified
          delete params.data.dbEmail
          delete params.data.auth_id
          delete params.data.fcm_token
        }

        finalQuery = cloneQuery(updateQuery)
        finalQuery.args.table = { name: tableName, schema }
        finalQuery.args.$set = params.data
        finalQuery.args.where = {}
        if (Array.isArray(pkValue)) {
          delete finalQuery.args.$set.id
          pkValue.forEach((p, i) => {
            finalQuery.args.where[p] = { $eq: params.id.split('_')[i] }
          })
          updateFields.push(pkValue[0])
          updateFields.push(pkValue[1])
          finalQuery.args.returning = updateFields.filter((f) => f !== DEFAULT_PRIMARY_KEY)
        } else {
          finalQuery.args.where[primaryKey] = { $eq: params.id }
          updateFields.push(primaryKey)
          finalQuery.args.returning = updateFields
        }
        break
      case 'UPDATE_MANY':
        // update multiple ids with given data
        const updateManyFields = Object.keys(params.data).filter((k) => k !== 'emailVerified')

        finalQuery = cloneQuery(updateQuery)
        finalQuery.args.table = { name: tableName, schema }
        finalQuery.args.$set = params.data
        finalQuery.args.where = {}
        finalQuery.args.where[primaryKey] = { $in: params.ids }
        updateManyFields.push(primaryKey)
        finalQuery.args.returning = updateManyFields
        break
      case 'DELETE':
        // delete one
        const deleteFields = Object.keys(params.previousData)

        finalQuery = cloneQuery(deleteQuery)
        finalQuery.args.table = { name: tableName, schema }
        finalQuery.args.where = {}
        if (Array.isArray(pkValue)) {
          pkValue.forEach((p, i) => {
            finalQuery.args.where[p] = { $eq: params.id.split('_')[i] }
          })
          deleteFields.push(pkValue[0])
          deleteFields.push(pkValue[1])
          finalQuery.args.returning = deleteFields.filter((f) => f !== DEFAULT_PRIMARY_KEY)
        } else {
          finalQuery.args.where[primaryKey] = { $eq: params.id }
          deleteFields.push(primaryKey)
          finalQuery.args.returning = deleteFields
        }
        break
      case 'DELETE_MANY':
        // delete multiple
        finalQuery = cloneQuery(deleteQuery)
        finalQuery.args.table = { name: tableName, schema }
        finalQuery.args.where = {}
        finalQuery.args.where[primaryKey] = { $in: params.ids }
        finalQuery.args.returning = [primaryKey]
        break
      case 'GET_MANY':
        // select multiple within where clause
        finalQuery = cloneQuery(selectQuery)
        finalQuery.args.table = { name: tableName, schema }
        finalQuery.args.where = {}
        finalQuery.args.where[primaryKey] = { $in: params.ids }
        finalQuery.args.where = addFilters(finalQuery.args.where, params.filter)
        break
      case 'GET_MANY_REFERENCE':
        // select multiple with relations
        const finalManyQuery = cloneQuery(selectQuery)
        const finalManyCountQuery = cloneQuery(countQuery)

        finalManyQuery.args.table = { name: tableName, schema }
        finalManyQuery.args.limit = params.pagination.perPage
        finalManyQuery.args.offset = (params.pagination.page * params.pagination.perPage) - params.pagination.perPage
        finalManyQuery.args.where = { [params.target]: params.id }
        finalManyQuery.args.where = addFilters(finalManyQuery.args.where, params.filter)
        finalManyQuery.args.order_by = { column: params.sort.field || primaryKey, type: params.sort.order.toLowerCase() }
        finalManyCountQuery.args.table = { name: tableName, schema }
        finalManyCountQuery.args.where = {}
        finalManyCountQuery.args.where[primaryKey] = { $ne: null }
        finalManyCountQuery.args.where = addFilters(finalManyQuery.args.where, params.filter)
        finalQuery = cloneQuery(bulkQuery)
        finalQuery.args.push(finalManyQuery)
        finalQuery.args.push(finalManyCountQuery)
        break
      default:
        throw new Error(`Unsupported type ${type}`)
    }
    options.body = JSON.stringify(finalQuery)
    return { options }
  }

  const convertHTTPResponse = (response, type, resource) => {
    // handle errors and throw with the message
    if ('error' in response || 'code' in response) {
      throw new Error(JSON.stringify(response))
    }
    const primaryKey = getPrimaryKey(resource)

    if (primaryKey !== DEFAULT_PRIMARY_KEY) {
      if (Array.isArray(response[0])) {
        response[0].forEach((res) => {
          res[DEFAULT_PRIMARY_KEY] = Array.isArray(primaryKey)
            ? `${res[primaryKey[0]]}_${res[primaryKey[1]]}`
            : `${res[primaryKey]}`
        })
      } else if (response.returning) {
        response.returning[0][DEFAULT_PRIMARY_KEY] = Array.isArray(primaryKey)
          ? `${response.returning[0][primaryKey[0]]}_${response.returning[0][primaryKey[1]]}`
          : response.returning[0][primaryKey]
      } else {
        response[0][DEFAULT_PRIMARY_KEY] = Array.isArray(primaryKey)
          ? `${response[0][primaryKey[0]]}_${response[0][primaryKey[1]]}`
          : response[0][primaryKey]
      }
    }

    switch (type) {
      case 'GET_LIST':
        return {
          data: response[0],
          total: response[1].count,
        }
      case 'GET_ONE':
        return {
          data: response[0],
        }
      case 'CREATE':
        return {
          data: response.returning[0],
        }
      case 'UPDATE':
        return {
          data: response.returning[0],
        }
      case 'UPDATE_MANY':
        const updatedIds = response.returning.map((item) => item.id)

        return {
          data: updatedIds,
        }
      case 'DELETE':
        return {
          data: response.returning[0],
        }
      case 'DELETE_MANY':
        const deletedIds = response.returning.map((item) => item.id)

        return {
          data: deletedIds,
        }
      case 'GET_MANY':
        return {
          data: response,
        }
      case 'GET_MANY_REFERENCE':
        return {
          data: response[0],
          total: response[1].count,
        }
      default:
        return { data: response }
    }
  }

  return async (type, resource, params) => {
    const { options } = convertDataRequestToHTTP(type, resource, params)
    options.method = 'POST'
    options.headers = headers

    let token = ''
    if (firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken()
      if (token) options.headers.authorization = `Bearer ${token}`
    }

    const response = await fetch(serverEndpoint, options)
    const data = await response.json()
    let modifiedData = data
    if (resource === 'user' && type.includes('GET')) {
      if (type === 'GET_LIST') modifiedData[0] = await getRemoteData(modifiedData[0], params, token)
      else modifiedData = await getRemoteData(modifiedData, params, token)
    }

    if (resource === 'adjustment' && type === 'CREATE') {
      try {
        await checkFundBeforeAdjustment(data, params, token)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    return convertHTTPResponse(modifiedData, type, resource, params)
  }
}

const checkFundBeforeAdjustment = async (data, params, token) => {
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: `{
        fund(
          where: {
            user_id: { _eq: "${data.returning[0].user_id}" }
            account_id: { _eq: "${data.returning[0].account_id}" }
          }    
        ) {
          points
        }
      }`,
    }),
  }

  const response = await fetch(`${process.env.REACT_APP_GRAPHQL_BASE}/v1/graphql`, options)
  const result = await response.json()
  const currentPoints = get(result, 'data.fund[0].points', 0)
  if (data.returning[0].type === 'DEBIT') {
    if (currentPoints - data.returning[0].points < 0) {
      throw new Error(`User currently has ${currentPoints} points and you are trying to debit ${data.returning[0].points}, adjustment must result in a positive number of points`)
    }
  } else if (data.returning[0].type === 'CREDIT') {
    if (currentPoints + data.returning[0].points < 0) {
      throw new Error(`User currently has ${currentPoints} points and you are trying to credit ${data.returning[0].points}, adjustment must result in a positive number of points`)
    }
  }
}

const getRemoteData = async (data, params, token) => {
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: Array.isArray(data) ? `{
        listUsers {
          users {
            uid
            displayName
            email
            emailVerified
          }
        }
      }` : `{
        getUser(uid: "${data.auth_id}") {
          displayName
          email
          emailVerified
        }   
      }`,
    }),
  }

  const response = await fetch(`${process.env.REACT_APP_GRAPHQL_BASE}/v1/graphql`, options)
  const result = await response.json()

  if (Array.isArray(data)) {
    return orderBy(get(result, 'data.listUsers.users', []).map((u) => {
      const dbUser = data.find((d) => d.auth_id === u.uid)
      return {
        ...dbUser,
        name: get(u, 'displayName'),
        email: get(u, 'email') || '-',
        emailVerified: get(u, 'emailVerified', false),
        dbEmail: get(dbUser, 'email'),
      }
    }), [get(params, 'sort.field')], [get(params, 'sort.order', '').toLowerCase()]).filter((d) => d.id)
  }
  return ({
    ...data,
    name: get(result, 'data.getUser.displayName', ''),
    email: get(result, 'data.getUser.email') || '-',
    emailVerified: get(result, 'data.getUser.emailVerified', false),
    dbEmail: data.email,
  })
}
