import React from 'react'
import { Filter, SelectInput, ReferenceInput } from 'react-admin'

const EventListFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="User"
      source="user_id"
      reference="user"
      allowEmpty={false}
      sort={{ field: 'email', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput
      label="Account"
      source="account_id"
      reference="account"
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Geofence"
      source="geofence_id"
      reference="geofence"
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      label="Action"
      source="geofence_action"
      allowEmpty={false}
      choices={[
        { id: 'ENTER', name: 'ENTER' },
        { id: 'EXIT', name: 'EXIT' },
      ]}
    />
    <SelectInput
      label="Status"
      source="status"
      allowEmpty={false}
      choices={[
        { id: 'APPROVED', name: 'APPROVED' },
        { id: 'REJECTED', name: 'REJECTED' },
        { id: 'VOID', name: 'VOID' },
        { id: 'PENDING', name: 'PENDING' },
      ]}
    />
  </Filter>
)

export default EventListFilter
