import React from 'react'
import {
  List, Datagrid, TextField, EditButton,
  ReferenceField, NumberField,
} from 'react-admin'

import RewardListFilter from './RewardListFilter'

const RewardList = props => (
  <List
    {...props}
    sort={{ field: 'account.name', order: 'ASC' }}
    bulkActions={false}
    filters={<RewardListFilter />}
  >
    <Datagrid>
      <ReferenceField source="account_id" reference="account" label="Owner" sortBy="account.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <NumberField source="points" />
      <TextField source="status" />
      <TextField source="type" />
      <EditButton />
    </Datagrid>
  </List>
)

export default RewardList
