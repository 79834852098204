/* eslint-disable no-alert */
import React from 'react'
import { CardActions } from 'react-admin'
import SecurityIcon from '@material-ui/icons/Security'
import MuiButton from '@material-ui/core/Button'

import firebase from '../../utils/firebase'

const question = 'This will send the user an email to reset their password. '
+ 'Typically, this is only done when signing up a client. '
+ 'Are you sure you want to continue?'


const CustomActions = ({ data }) => {
  const onClick = async () => {
    if (!window.confirm(question)) return
    try {
      await firebase.auth().sendPasswordResetEmail(data.email)
      alert(`Email containing a reset link was sent to ${data.email}`)
    } catch (error) {
      alert(error.toString())
    }
  }

  return (
    <CardActions>
      <MuiButton
        onClick={onClick}
        style={{
          fontSize: 13,
          color: 'white',
          marginBottom: 20,
        }}
      >
        <SecurityIcon />
        &nbsp;&nbsp;Reset Password
      </MuiButton>
    </CardActions>
  )
}

export default CustomActions
