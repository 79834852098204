import React from 'react'
import {
  Datagrid, TextField, EditButton, Edit,
  TextInput, ReferenceField, SelectInput, NumberInput,
  LongTextInput, required, minLength, minValue,
  TabbedForm, FormTab, DateField, Pagination,
  ReferenceManyField,
} from 'react-admin'

import RewardEditTitle from './RewardEditTitle'
import CustomActions from './CustomActions'
import CustomToolbar from './CustomToolbar'

const validateName = [required()]
const validateDescription = [required(), minLength(20)]
const validatePoints = [required(), minValue(5)]
const validateStatus = [required()]

const RewardEdit = props => (
  <Edit title={<RewardEditTitle />} actions={<CustomActions />} {...props}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="General">
        <TextField source="id" />
        <ReferenceField source="account_id" reference="account" label="Owner">
          <TextField source="name" />
        </ReferenceField>
        <TextInput source="name" validate={validateName} />
        <LongTextInput source="description" validate={validateDescription} />
        <NumberInput source="points" validate={validatePoints} />
        <SelectInput
          source="status"
          validate={validateStatus}
          choices={[
            { id: 'ACTIVE', name: 'ACTIVE' },
            { id: 'INACTIVE', name: 'INACTIVE' },
          ]}
        />
        <TextField source="type" />
      </FormTab>
      <FormTab label="Redemptions">
        <ReferenceManyField
          sort={{ field: 'timestamp', order: 'DESC' }}
          label="Reward redemptions"
          reference="redemption"
          target="reward_id"
          pagination={<Pagination />}
          perPage={10}
        >
          <Datagrid>
            <ReferenceField source="user_id" reference="user" label="User" linkType>
              <TextField source="email" />
            </ReferenceField>
            <DateField source="timestamp" showTime label="Created at" />
            <TextField source="points" />
            <TextField source="status" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default RewardEdit
