import React from 'react'
import {
  Edit, TabbedForm, FormTab, TextField,
  SelectInput, BooleanInput, ReferenceManyField,
  Datagrid, ReferenceField, FormDataConsumer, Button,
  DateField, ShowButton, Pagination, EditButton,
  required, email,
} from 'react-admin'
import CreateIcon from '@material-ui/icons/Create'

import CustomActions from './CustomActions'
import CustomToolbar from './CustomToolbar'
import UserEditTitle from './UserEditTitle'

import MapView from '../../components/MapView'

const validateName = [required()]
const validateEmail = [required(), email()]
const validateRole = [required()]

const UserEdit = ({ history, ...rest }) => (
  <Edit title={<UserEditTitle />} history={history} actions={<CustomActions />} {...rest}>
    <TabbedForm toolbar={<CustomToolbar />}>
      <FormTab label="General">
        <TextField source="id" label="Database ID" />
        <TextField source="auth_id" label="Firebase ID" />
        <TextField source="name" validate={validateName} />
        <TextField source="email" label="Firebase email" type="email" validate={validateEmail} />
        <TextField source="dbEmail" label="Database email" type="email" validate={validateEmail} />
        <BooleanInput source="emailVerified" />
        <SelectInput
          source="role"
          validate={validateRole}
          choices={[
            { id: 'ADMIN', name: 'ADMIN' },
            { id: 'CLIENT', name: 'CLIENT' },
            { id: 'MOBILE', name: 'MOBILE' },
          ]}
        />
      </FormTab>
      <FormTab label="Notifications">
        <BooleanInput source="point_notifications" />
        <BooleanInput source="redemption_notifications" />
        <TextField source="fcm_token" />
      </FormTab>
      <FormTab label="Accounts">
        <ReferenceManyField
          sort={{ field: 'account_id', order: 'ASC' }}
          label="Accounts this user has access to"
          reference="user_account"
          target="user_id"
        >
          <Datagrid>
            <ReferenceField source="account_id" reference="account" label="Name" linkType={false}>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="account_id" reference="account" label="Type" linkType={false}>
              <TextField source="type" />
            </ReferenceField>
            <FormDataConsumer>
              {({ record }) => (
                <Button label="Edit" onClick={() => history.push(`/account/${record.account_id}`)}>
                  <CreateIcon />
                </Button>
              )}
            </FormDataConsumer>
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Events">
        <ReferenceManyField
          sort={{ field: 'timestamp', order: 'DESC' }}
          filter={{ type: 'geofence' }}
          label="Geofence events triggered by this user"
          reference="event"
          target="user_id"
          pagination={<Pagination />}
          perPage={10}
        >
          <Datagrid>
            <TextField source="status" />
            <TextField source="geofence_action" label="Action" />
            <TextField source="points" />
            <DateField source="timestamp" showTime label="Created at" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          sort={{ field: 'timestamp', order: 'DESC' }}
          filter={{ type: 'geofence' }}
          label=""
          reference="event"
          target="user_id"
        >
          <MapView />
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Funds">
        <ReferenceManyField
          sort={{ field: 'points', order: 'DESC' }}
          label="Available funds for this user"
          reference="fund"
          target="user_id"
          pagination={<Pagination />}
          perPage={10}
        >
          <Datagrid>
            <ReferenceField source="account_id" reference="account" label="Name" linkType>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="points" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Redemptions">
        <ReferenceManyField
          sort={{ field: 'timestamp', order: 'DESC' }}
          label="Redemptions made by this user"
          reference="redemption"
          target="user_id"
          pagination={<Pagination />}
          perPage={10}
        >
          <Datagrid>
            <ReferenceField source="account_id" reference="account" label="Owner" linkType>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="reward_id" reference="reward" label="Reward" linkType>
              <TextField source="name" />
            </ReferenceField>
            <DateField source="timestamp" showTime label="Created at" />
            <TextField source="points" />
            <TextField source="status" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default UserEdit
