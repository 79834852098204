import React from 'react'
import {
  List, Datagrid, TextField, ReferenceField,
} from 'react-admin'

import UserAccountListFilter from './UserAccountListFilter'

const UserAccountList = props => (
  <List
    {...props}
    title="Members"
    sort={{ field: 'account.name', order: 'ASC' }}
    bulkActions={false}
    filters={<UserAccountListFilter />}
  >
    <Datagrid>
      <ReferenceField source="account_id" reference="account" sortBy="account.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="user_id" reference="user" sortBy="user.email">
        <TextField source="email" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default UserAccountList
