import React from 'react'
import {
  List, Datagrid, TextField, ReferenceField,
  ShowButton, DateField,
} from 'react-admin'

import EventListFilter from './EventListFilter'

const EventList = props => (
  <List
    {...props}
    sort={{ field: 'timestamp', order: 'DESC' }}
    filter={{ type: 'geofence' }}
    bulkActions={false}
    filters={<EventListFilter />}
  >
    <Datagrid>
      <ReferenceField source="user_id" reference="user" sortBy="user.email">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="account_id" reference="account" sortBy="account.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="geofence_id" reference="geofence" sortBy="geofence.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="geofence_action" label="Action" />
      <TextField source="status" />
      <DateField source="timestamp" showTime label="Device timestamp" />
      <TextField source="app_version" />
      <ShowButton />
    </Datagrid>
  </List>
)

export default EventList
