import React from 'react'
import {
  SelectInput, NumberInput, ReferenceInput, Filter,
} from 'react-admin'

const RewardListFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Owner"
      source="account_id"
      reference="account"
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="points" step={1} />
    <SelectInput
      label="Status"
      source="status"
      allowEmpty={false}
      choices={[
        { id: 'ACTIVE', name: 'ACTIVE' },
        { id: 'INACTIVE', name: 'INACTIVE' },
      ]}
    />
    <SelectInput
      label="Type"
      source="type"
      allowEmpty={false}
      choices={[
        { id: 'IN_PERSON', name: 'IN_PERSON' },
        { id: 'CLAIMABLE', name: 'CLAIMABLE' },
        { id: 'ONLINE', name: 'ONLINE' },
      ]}
    />
  </Filter>
)

export default RewardListFilter
