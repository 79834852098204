import React from 'react'
import { Toolbar, SaveButton } from 'react-admin'

const CustomToolbar = props => (
  <Toolbar>
    <SaveButton {...props} />
  </Toolbar>
)

export default CustomToolbar
