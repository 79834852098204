import { createMuiTheme } from '@material-ui/core/styles'

const theme = dark => createMuiTheme({
  palette: {
    primary: { main: '#8081DD' },
    secondary: { main: '#8081DD' },
    type: dark ? 'dark' : 'light',
  },
})

export default theme
