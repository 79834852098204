import React from 'react'
import {
  List, Datagrid, TextField, ShowButton,
  ReferenceField,
} from 'react-admin'

import FundListFilter from './FundListFilter'

const FundList = props => (
  <List
    {...props}
    sort={{ field: 'user.email', order: 'ASC' }}
    bulkActions={false}
    filters={<FundListFilter />}
  >
    <Datagrid>
      <ReferenceField source="user_id" reference="user" sortBy="user.email">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="account_id" reference="account" sortBy="account.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="points" />
      <ShowButton />
    </Datagrid>
  </List>
)

export default FundList
