import React from 'react'
import {
  SimpleForm, SelectInput, TextInput, LongTextInput,
  Create, required, minLength,
} from 'react-admin'

const validateName = [required()]
const validateDescription = [required(), minLength(20)]
const validateCtaText = [required(), minLength(3)]
const validateCtaUrl = [required()]
const validateCtaImage = [required()]
const validateType = [required()]

const AccountCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateName} />
      <LongTextInput source="description" validate={validateDescription} />
      <TextInput source="cta_text" validate={validateCtaText} />
      <TextInput source="cta_url" validate={validateCtaUrl} />
      <TextInput source="cta_image" validate={validateCtaImage} />
      <SelectInput
        validate={validateType}
        source="type"
        choices={[
          { id: 'BUSINESS', name: 'BUSINESS' },
          { id: 'PERSONAL', name: 'PERSONAL' },
        ]}
      />
    </SimpleForm>
  </Create>
)

export default AccountCreate
