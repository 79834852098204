import React from 'react'
import { Toolbar, SaveButton } from 'react-admin'

const AccountEditToolbar = props => (
  <Toolbar>
    <SaveButton {...props} />
  </Toolbar>
)

export default AccountEditToolbar
