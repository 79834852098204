import React from 'react'
import ReactDOM from 'react-dom'

import App from './components/App'
import EnvBanner from './components/EnvBanner'

import './index.css'

ReactDOM.render(
  <div>
    <EnvBanner />
    <App />
  </div>,
  document.getElementById('root'),
)
