import React from 'react'
import {
  SimpleForm, TextInput, SelectInput, NumberInput,
  LongTextInput, Create, ReferenceInput, required,
  minLength, minValue,
} from 'react-admin'

const validateOwner = [required()]
const validateName = [required()]
const validateDescription = [required(), minLength(20)]
const validatePoints = [required(), minValue(5)]
const validateStatus = [required()]
const validateType = [required()]

const RewardCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source="account_id"
        reference="account"
        label="Owner"
        validate={validateOwner}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" validate={validateName} />
      <LongTextInput source="description" validate={validateDescription} />
      <NumberInput source="points" validate={validatePoints} />
      <SelectInput
        source="status"
        validate={validateStatus}
        choices={[
          { id: 'ACTIVE', name: 'ACTIVE' },
          { id: 'INACTIVE', name: 'INACTIVE' },
        ]}
      />
      <SelectInput
        source="type"
        validate={validateType}
        choices={[
          { id: 'IN_PERSON', name: 'IN_PERSON' },
          { id: 'CLAIMABLE', name: 'CLAIMABLE' },
          { id: 'ONLINE', name: 'ONLINE' },
        ]}
      />
    </SimpleForm>
  </Create>
)

export default RewardCreate
