import React from 'react'
import { Layout as RaLayout } from 'react-admin'
import { css } from 'styled-components/macro'

import AppBar from './AppBar'

const Layout = (props) => {
  let style
  switch (process.env.REACT_APP_PROJECT_ID) {
    case 'pbeeapp-dev':
    case 'pbeeapp-staging':
      style = css`
      .headroom {
        top: 39px !important;
      }
    `
      break
    default:
  }

  return (
    <RaLayout
      {...props}
      css={style}
      appBar={AppBar}
    />
  )
}

export default Layout
