const endpoint = `${process.env.REACT_APP_GRAPHQL_BASE}/v1/query`
const headers = {
  'content-type': 'application/json',
}
const config = {
  pagination: {
    perPage: 100,
  },
  primaryKey: {
    fund: ['user_id', 'account_id'],
    user_account: ['user_id', 'account_id'],
  },
}

export {
  endpoint,
  headers,
  config,
}
