import React from 'react'
import {
  TextField, Edit, SimpleForm, ReferenceField,
  SelectInput, DateField, LongTextInput, FormDataConsumer,
  required,
} from 'react-admin'

import CustomToolbar from './CustomToolbar'

const validateStatus = [required()]

const RedemptionEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextField source="id" />
      <ReferenceField source="user_id" reference="user" label="User Email">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="account_id" reference="account" label="Reward Owner">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="reward_id" reference="reward">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="points" />
      <FormDataConsumer>
        {({ formData }) => (
          formData.reward_type === 'ONLINE' && <LongTextInput source="online_code" />
        )}
      </FormDataConsumer>
      <DateField source="timestamp" showTime label="Started at" />
      <DateField source="completed_timestamp" showTime label="Completed at" />
      <TextField source="reward_type" />
      <TextField source="status" />
      <FormDataConsumer>
        {({ record }) => (
          record.status === 'APPROVED' ? (
            <SelectInput
              source="status"
              validate={validateStatus}
              choices={[
                { id: 'APPROVED', name: 'APPROVED' },
                { id: 'COMPLETED', name: 'COMPLETED' },
              ]}
            />
          ) : <TextField source="status" />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)

export default RedemptionEdit
