import React from 'react'
import {
  SimpleForm, NumberInput, Create, ReferenceInput,
  SelectInput, required, minValue, maxValue,
  LongTextInput,
} from 'react-admin'

const validateUser = [required()]
const validateAccount = [required()]
const validatePoints = [required(), minValue(1), maxValue(500)]
const validateType = [required()]

const AdjustmentCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source="user_id"
        reference="user"
        validate={validateUser}
        sort={{ field: 'email', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput
        source="account_id"
        reference="account"
        validate={validateAccount}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="type"
        validate={validateType}
        choices={[
          { id: 'DEBIT', name: 'DEBIT' },
          { id: 'CREDIT', name: 'CREDIT' },
        ]}
      />
      <NumberInput source="points" validate={validatePoints} />
      <LongTextInput source="comment" />
    </SimpleForm>
  </Create>
)

export default AdjustmentCreate
