import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK,
} from 'react-admin'

import firebase from './firebase'

const authProvider = async (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    try {
      const { username, password } = params
      await firebase.auth().signInWithEmailAndPassword(username, password)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    try {
      await firebase.auth().signOut()
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params
    if (status === 401 || status === 403) {
      await firebase.auth().signOut()
      return Promise.reject()
    }
    return Promise.resolve()
  }

  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    if (!firebase.auth().currentUser) {
      return Promise.reject(new Error('Oops! You don\'t seem to be signed in.'))
    }
  }

  return Promise.resolve()
  // return Promise.reject(Error('Unknown method'))
}

export default authProvider
