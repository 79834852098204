import React from 'react'
import {
  Create, SimpleForm, TextInput, SelectInput,
  required, email,
} from 'react-admin'

const validateName = [required()]
const validateEmail = [required(), email()]
const validateRole = [required()]

const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateName} />
      <TextInput source="email" type="email" validate={validateEmail} />
      <SelectInput
        source="role"
        validate={validateRole}
        choices={[
          { id: 'ADMIN', name: 'ADMIN' },
          { id: 'CLIENT', name: 'CLIENT' },
          { id: 'MOBILE', name: 'MOBILE' },
        ]}
      />
    </SimpleForm>
  </Create>
)

export default UserCreate
