import React from 'react'
import {
  TextInput, NumberInput, Create, ReferenceInput,
  TabbedForm, FormTab, SelectInput, required,
  minValue, FormDataConsumer, maxValue,
} from 'react-admin'
import get from 'lodash.get'

import Map from '../../components/Map'

const validateOwner = [required()]
const validateName = [required()]
const validateRefreshRate = [required(), minValue(30), maxValue(43200, 'Must be 43,200 (30 days) or less')]
const validatePoints = [required(), minValue(5), maxValue(15)]
const validateRadius = [required(), minValue(50), maxValue(500)]
const validateType = [required()]
const validateStatus = [required()]
const validateLocation = [required()]

const GeofenceCreate = props => (
  <Create {...props}>
    <TabbedForm defaultValue={{ location: { type: 'Point', crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:EPSG::4326' } } } }}>
      <FormTab label="General">
        <ReferenceInput
          source="account_id"
          reference="account"
          label="Owner"
          validate={validateOwner}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={10000}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={validateName} />
        <NumberInput source="refresh_rate" validate={validateRefreshRate} label="Refresh rate (milliseconds)" />
        <NumberInput source="points" validate={validatePoints} />
        <NumberInput source="radius" validate={validateRadius} label="Radius (meters)" />
        <SelectInput
          source="type"
          validate={validateType}
          choices={[
            { id: 'STANDARD', name: 'STANDARD' },
          ]}
        />
        <SelectInput
          source="status"
          validate={validateStatus}
          choices={[
            { id: 'ACTIVE', name: 'ACTIVE' },
            { id: 'INACTIVE', name: 'INACTIVE' },
          ]}
        />
      </FormTab>
      <FormTab label="Location">
        <NumberInput source="location.coordinates[1]" label="Latitude" validate={validateLocation} />
        <NumberInput source="location.coordinates[0]" label="Longitude" validate={validateLocation} />
        <FormDataConsumer>
          {({ formData }) => {
            const markers = []
            const lat = get(formData, 'location.coordinates[1]')
            const lng = get(formData, 'location.coordinates[0]')
            const radius = get(formData, 'radius', 200)

            if (lat && lng) {
              markers.push({
                key: 0,
                lat,
                lng,
                radius,
              })
            }

            return (
              <Map
                markers={markers}
                center={{ lat, lng }}
                defaultZoom={15.2}
              />
            )
          } /* pass in fake ID */}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Create>
)

export default GeofenceCreate
