import React from 'react'
import {
  TextField, Show, SimpleShowLayout,
  ReferenceField,
} from 'react-admin'

const FundShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="user_id" reference="user">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="account_id" reference="account">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="points" />
    </SimpleShowLayout>
  </Show>
)

export default FundShow
