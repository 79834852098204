import React, { useState } from 'react'
import GoogleMap from 'google-map-react'
import { meters2ScreenPixels } from 'google-map-react/utils'

import Marker from './Marker'

const getMapBounds = (map, maps, locations) => {
  const bounds = new maps.LatLngBounds()

  locations.forEach((location) => {
    bounds.extend(
      new maps.LatLng(location.lat, location.lng),
    )
  })

  return bounds
}

const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds)
    })
  })
}

const apiIsLoaded = (map, maps, locations) => {
  if (!map) return

  const bounds = getMapBounds(map, maps, locations)
  map.fitBounds(bounds)
  bindResizeListener(map, maps, bounds)
}

const Map = (
  {
    defaultZoom, height, width, markers,
    center, boundToMarkers,
  },
) => {
  const [zoom, setZoom] = useState(defaultZoom)

  return (
    <div style={{ height, width }}>
      <GoogleMap
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        center={center}
        zoom={zoom}
        onBoundsChange={(_, z) => setZoom(z)}
        onGoogleApiLoaded={({ map, maps }) => boundToMarkers && apiIsLoaded(map, maps, markers)}
        yesIWantToUseGoogleMapApiInternals={boundToMarkers}
      >
        {markers.map(({
          key, lat, lng, radius,
        }) => {
          const { w, h } = !radius
            ? { w: 20, h: 20 }
            : meters2ScreenPixels(radius, { lat, lng }, zoom)

          return (
            <Marker
              key={key}
              lat={lat}
              lng={lng}
              width={w}
              height={h}
            />
          )
        })}
      </GoogleMap>
    </div>
  )
}

Map.defaultProps = {
  center: {
    lat: 59.95,
    lng: 30.33,
  },
  defaultZoom: 10,
  height: '250px',
  width: '250px',
  markers: [],
  boundToMarkers: false,
}

export default Map
