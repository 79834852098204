import React from 'react'
import {
  List, Datagrid, TextField, ShowButton,
  DateField, ReferenceField,
} from 'react-admin'

import AdjustmentListFilter from './AdjustmentListFilter'

const AdjustmentList = props => (
  <List
    {...props}
    sort={{ field: 'created_at', order: 'DESC' }}
    bulkActions={false}
    filters={<AdjustmentListFilter />}
  >
    <Datagrid>
      <ReferenceField source="user_id" reference="user" sortBy="user.email">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="account_id" reference="account" sortBy="account.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="points" />
      <TextField source="status" />
      <DateField source="created_at" showTime label="Created at" />
      <ShowButton />
    </Datagrid>
  </List>
)

export default AdjustmentList
