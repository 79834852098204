import React from 'react'
import {
  NumberInput, ReferenceInput, SelectInput, Filter,
} from 'react-admin'

const GeofenceListFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Owner"
      source="account_id"
      reference="account"
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="points" step={1} />
    <NumberInput source="refresh_rate" step={1} />
    <NumberInput source="radius" step={1} />
    <SelectInput
      label="Status"
      source="status"
      allowEmpty={false}
      choices={[
        { id: 'ACTIVE', name: 'ACTIVE' },
        { id: 'INACTIVE', name: 'INACTIVE' },
      ]}
    />
  </Filter>
)

export default GeofenceListFilter
