import React from 'react'
import { css } from 'styled-components/macro'

import theme from '../utils/theme'

const Marker = (
  {
    lat, lng, width, height,
  },
) => {
  const w = width < 10 ? 10 : width
  const h = height < 10 ? 10 : height

  return (
    <div
      lat={lat}
      lng={lng}
      css={css`
        width: ${w}px;
        height: ${h}px;
        background-color: ${theme().palette.primary.main};
        border-radius: 500px;
        opacity: .7;
        right: ${w / 2}px;
        bottom: ${h / 2}px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        lat={lat}
        lng={lng}
        css={css`
          background-color: black;
          opacity: .7;
          border-radius: 100px;
          width: ${10}px;
          height: ${10}px;
        `}
      />
    </div>
  )
}

export default Marker
