import React from 'react'
import {
  Filter, SelectInput, ReferenceInput, NumberInput,
} from 'react-admin'

const RedemptionListFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="User"
      source="user_id"
      reference="user"
      allowEmpty={false}
      sort={{ field: 'email', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput
      label="Owner"
      source="account_id"
      reference="account"
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="points" step={1} />
    <SelectInput
      label="Status"
      source="status"
      allowEmpty={false}
      choices={[
        { id: 'APPROVED', name: 'APPROVED' },
        { id: 'COMPLETED', name: 'COMPLETED' },
        { id: 'REJECTED', name: 'REJECTED' },
      ]}
    />
    <SelectInput
      label="Reward type"
      source="reward_type"
      allowEmpty={false}
      choices={[
        { id: 'IN_PERSON', name: 'IN_PERSON' },
        { id: 'CLAIMABLE', name: 'CLAIMABLE' },
        { id: 'ONLINE', name: 'ONLINE' },
      ]}
    />
  </Filter>
)

export default RedemptionListFilter
